import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface LocationModel {
  id: null | string
  name: string,
  streetAddressOne: string | null | undefined,
  streetAddressTwo: string | null | undefined,
  city: string | null | undefined,
  state: string | null | undefined,
  postalCode: string | null | undefined,
  country: string | null | undefined
}

export interface PersonModel {
  id: null | string,
  company: {
    id: null | string,
    defaultLocation: LocationModel | null
  }
}

export interface PersonStoreState extends PersonModel {
  setState: (data: PersonModel) => void,
  setDefaultLocation: (location: LocationModel) => void,
  destroy: () => void
}

const initialState = {
  id: null,
  company: {
    id: null,
    defaultLocation: null
  },
}

const usePersonStore = create<PersonStoreState>()(
  /* @ts-ignore */
  persist(
    (set) => ({
      id: null,
      company: {
        id: null,
        defaultLocation: null,
      },
      setState: (data) => set(() => data),
      setDefaultLocation: (location: LocationModel) => set((state) => ({
        ...state,
        company: {
          ...state.company,
          defaultLocation: location
        }
      })),
      destroy: () => set(() => initialState)
    }),
    {
      name: 'active-person',
      getStorage: () => sessionStorage
    }
  ),
);

export default usePersonStore;