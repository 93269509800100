import { ReactNode, useEffect, useState } from "react";
import { Box, Typography, Button, Paper, CircularProgress } from "@mui/material";
import { getFragmentData, gql } from "../../../../../__generated__";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CompanyLocationsQuery,
  SelectLocation_LocationFragment,
  SelectLocation_LocationFragmentDoc,
} from "../../../../../__generated__/graphql";
import { useSlideInContext } from "../context/SlideInProvider";
import usePersonStore, { LocationModel } from "../../../personStore";

const COMPANY_LOCATIONS = gql(`
  query CompanyLocations {
      me {
        company {
          id
          locations {
            ...SelectLocation_Location
          }
        }
        defaultLocation {
          id
        }
      }
    }

    fragment SelectLocation_Location on Location {
      id
      name
      streetLineOne
      streetLineTwo
      city
      state
      postalCode
      country
    }
`);

const SET_DEFAULT_LOCATION_MUTATION = gql(`
  mutation SetDefaultLocation($id: UUID!) {
    setDefaultLocation(input: { id: $id }) {
      locationPerson {
        id
        type
        location {
          id
        }
      }
    }
  }
`);

function SelectionBox({
  isSelected,
  children,
  onClick
}: {
  isSelected: boolean;
  children: any;
  onClick: () => void
}) {
  if (!isSelected)
    return (
      <Paper
        elevation={1}
        sx={(p) => ({
          padding: 3,
          marginBottom: 2,
          "&:hover": {
            background: p.palette.primary.light,
            color: p.palette.primary.contrastText,
            cursor: 'pointer'
          },
        })}
        onClick={onClick}
      >
        {children}
      </Paper>
    );

  return (
    <Paper
      elevation={5}
      sx={(p) => ({
        background: p.palette.primary.light,
        color: p.palette.primary.contrastText,
        padding: 3,
        marginBottom: 2
      })}
    >
      {children}
    </Paper>
  );
}

function Loading() {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}

function LocationDetail(
  data: SelectLocation_LocationFragment,
  defaultLocationId: string,
  selectedLocationId: string | null | undefined,
  onClick: (location: SelectLocation_LocationFragment) => void
) {

  const filterUnusedComponents = (array: any) => array.filter((x: any) => x !== null && x !== '');

  const addressSegmentL = filterUnusedComponents([data.streetLineOne, data.streetLineTwo, data.city]);
  const addressSegmentR = filterUnusedComponents([data.state, data.postalCode, data.country]);
  const address = addressSegmentL.concat(addressSegmentR.join(' ')).join(', ');

  return (
    <SelectionBox isSelected={(!selectedLocationId && data.id === defaultLocationId) || data.id == selectedLocationId} onClick={() => onClick(data)}>
      <Typography style={{ fontSize: 16, fontWeight: "bold" }} align="left">
        {data.name}
      </Typography>
      <Box>
        <Typography align="left">
          { address }
        </Typography>
      </Box>
    </SelectionBox>
  );
}

function DisablePanel({ disable, children }: { disable: boolean, children: any }) {
  return (
    <Box sx={{
      opacity: disable ? 0.5 : 1,
      pointerEvents: disable ? 'none' : 'initial'
    }}>
      { children }
    </Box>
  )
}

export default function SelectLocation() {
  const setDefaultLocation = usePersonStore(state => state.setDefaultLocation);

  const [queryGetCompanyLocations, { loading }] = useLazyQuery(COMPANY_LOCATIONS, {
    notifyOnNetworkStatusChange: true,
  });

  const [mutationSetDefaultLocation, mutationSetDefaultLocationResponse] = useMutation(SET_DEFAULT_LOCATION_MUTATION)

  const { toggleDrawer } = useSlideInContext();

  const [selectedLocationId, setSelectedLocationId] = useState<string | null | undefined>(null);

  const [companyLocations, setCompanyLocations] = useState<
    CompanyLocationsQuery | undefined
  >({});

  useEffect(() => {
    queryGetCompanyLocations().then((x) => setCompanyLocations(x.data));
  }, []);
  
  const handleLocationClick = (location: SelectLocation_LocationFragment) => {
    console.log("Location!", location);
    setSelectedLocationId(location.id);
    mutationSetDefaultLocation({
      variables: {
        id: location.id
      },
      refetchQueries: [COMPANY_LOCATIONS],
      onCompleted: () => {
        setDefaultLocation(location as LocationModel);
        toggleDrawer()
      }
    });
  }

  const handleCancel = () => {
    toggleDrawer()
  }

  return (
    <DisablePanel disable={mutationSetDefaultLocationResponse?.loading ?? false}>
      { loading 
          ? <Loading />
          : companyLocations?.me?.company.locations.map((x) =>
            LocationDetail(
              getFragmentData(SelectLocation_LocationFragmentDoc, x),
              companyLocations?.me?.defaultLocation?.id,
              selectedLocationId,
              handleLocationClick
            )
          )
      }
      <Box
        display="flex"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        mt={4}
      >
        <Box>
          <Button onClick={handleCancel}>Cancel</Button>
        </Box>
      </Box>
    </DisablePanel>
  );
}
