import { Box, Grid } from "@mui/material";
import StudentsTable from "./StudentsTable";
import { useEffect, useState } from "react";
import { MeQuery, Student } from "../../__generated__/graphql";
import { usePlayerLobby } from "../../context/playerContext/PlayerDataProvider";
import RiskWarningGrid from "../Admin/components/RiskWarningGrid";
import { useNavigate } from "react-router";
import { useSlideInContext } from "../Admin/components/SlideIn/context/SlideInProvider";
import { LAUNCH_OPTIONS } from "../Admin/components/SlideIn/context/contentTypes";
import { gql, useLazyQuery } from "@apollo/client";
import usePersonStore, { PersonModel } from "../Admin/personStore";

const QUERY_ME = gql(`
  query Me {
    me {
      __typename
      id
      company {
        __typename
        id 
      }
      defaultLocation {
        __typename
        id
        name
        streetLineOne
        streetLineTwo
        city
        state
        postalCode
        country
      }
    }
  }
`);

function transformToState(data: MeQuery): PersonModel {
    return {
        id: data.me?.id,
        company: {
            id: data.me?.company.id,
            defaultLocation: (data.me?.defaultLocation as any),
        }
    }
}


export function DashboardPage() {
    const navigate = useNavigate();
    const { changePlayer } = usePlayerLobby();
    const { handleContent } = useSlideInContext();
    const [activeRecord, setActiveRecord] = useState<Student | null>(null);

    const [queryMe] = useLazyQuery(QUERY_ME, { notifyOnNetworkStatusChange: true});
    const setPersonStoreState = usePersonStore(state => state.setState);

    useEffect(() => {
        queryMe().then(response => setPersonStoreState(transformToState(response.data)));
    }, []);

    const handleSelectedStudent = (record: any) => {
        navigate(`/dashboard/student/${record.id}/sessions`)
    }

    const handleActionItem = (record: any) => {
        //@ts-ignore
        const edge = activeRecord?.students?.edges?.find(item => item.node.id === record.studentId) as StudentsEdge
        navigate(`/dashboard/student/${record.studentId}/sessions`)
        if (edge) {
            setActiveRecord(edge.node)
        }
    }

    const handleLaunch = (student: any) => {
        changePlayer(student);
        handleContent(LAUNCH_OPTIONS);
    }
    
    return (
        <Grid container rowSpacing={4} sx={{  paddingBottom: 4 }}>
            <Grid item xs={8}>
                <Box component="div" sx={{ marginTop: 4 }}>
                    <RiskWarningGrid onClickResults={handleActionItem} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <StudentsTable
                    onSelectedStudent={(student, action) => {
                        switch (action) {
                            case 'LAUNCH':
                                handleLaunch(student);
                                break;
                            case 'SESSIONS':
                                handleSelectedStudent(student);
                                break;
                            default:
                                throw Error(`Although the student ${student.id} is selected, action ${action} is not implemented`);
                        }
                    }} 
                />
            </Grid>
        </Grid>
    )
}