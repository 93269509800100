import React, { useEffect, useState } from 'react';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { format } from 'date-fns';

import { PEOPLE_FOR_LINK__PERSON_FRAGMENT } from '../../../features/graphql/query/person/fragments/peopleForLink__person';
import { FallbackCell } from '../../../components/FallbackCell';
import { getFragmentData } from '../../../__generated__';
import { PeopleForLinkQuery, PeopleForLink__PersonFragment } from '../../../__generated__/graphql';
import DescriptionIcon from '@mui/icons-material/Description';
import ManageTable from '../components/ManageTable';

type TableState = {
    columns: GridColDef[],
    rows: GridRowModel[]
    rowCount: number,
    page: number,
    pageSize: number,
}

type ExpireCallback = (person: PeopleForLink__PersonFragment) => void;
type DetailCallback = (person: PeopleForLink__PersonFragment) => void;

type TableProps = {
    data:  PeopleForLinkQuery | undefined,
    loading: boolean,
    onExpire: ExpireCallback
    onDetail: DetailCallback,
    onAddNew: () => void,
    onBack: () => void
}

const getPersonTableFields = (loading: boolean, onExpire: ExpireCallback, onDetail: DetailCallback): GridColDef[] => {
    const columns: GridColDef[] = [
        { 
            field: "name",
            headerName: "Name", 
            flex: 1,
            renderCell: (params) => {
                const fragment = getFragmentData(PEOPLE_FOR_LINK__PERSON_FRAGMENT, params.row);
                return <FallbackCell loading={loading}>{fragment.firstName} {fragment.lastName}</FallbackCell>
            }
        },
        {
            field: "startDate",
            headerName: "Start Date",
            flex: 1,
            resizable: false,
            editable: false,
            width: 80,
            renderCell: (params) =>
                <FallbackCell loading={loading}>
                { params?.value
                    ? format(new Date(params.value.toString()), "MM/dd/yyyy")
                    : ""}
                </FallbackCell>
        },
        {
            field: "endDate",
            headerName: "End Date",
            flex: 1,
            resizable: false,
            editable: false,
            width: 80,
            renderCell: (params) =>
                <FallbackCell loading={loading}>
                { params?.value
                    ? format(new Date(params.value.toString()), "MM/dd/yyyy")
                    : "" }
                </FallbackCell>
        },
        { 
            field: "roleType", 
            headerName: "Role / Type", 
            flex: 1,
            renderCell: (params) => {
                const fragment = getFragmentData(PEOPLE_FOR_LINK__PERSON_FRAGMENT, params.row);
                return <FallbackCell loading={loading}>{fragment.role?.name}</FallbackCell>
            }
        },
        {
            align: "center",
            field: 'details',
            headerName: 'Details',
            sortable: false,
            resizable: false,
            editable: false,
            width: 80,
            renderCell: (params) => {
                const fragment = getFragmentData(PEOPLE_FOR_LINK__PERSON_FRAGMENT, params.row);
                return (
                    <FallbackCell loading={loading}>
                        <IconButton onClick={() => onDetail(fragment)}>
                            <DescriptionIcon />
                        </IconButton>
                    </FallbackCell>
                )
            }
        },
        {
            align: "center",
            sortable: false,
            resizable: false,
            editable: false,
            disableColumnMenu: true,
            field: "id",
            width: 80,
            headerName: "Expire",
            renderCell: (params) => {
                const fragment = getFragmentData(PEOPLE_FOR_LINK__PERSON_FRAGMENT, params.row);
                return (
                    <FallbackCell loading={loading}>
                        <IconButton onClick={() => onExpire(fragment)}>
                            <CloseIcon
                                style={{
                                    backgroundColor: fragment.expired ? "#EEE" :  "#8E1C3B",
                                    borderRadius: "100%",
                                    color: fragment.expired ? "#999" : "#FFF",
                                }}
                            />
                        </IconButton>
                    </FallbackCell>
                )
            }
        }
    ];

    return columns
}

const PersonTable = ({ data, loading, onExpire, onDetail, onAddNew, onBack }: TableProps) => {

    const [dataGridState, setDataGridState] = useState<TableState>({
        columns: [],
        rows: [],
        rowCount: 0,
        page: 0,
        pageSize: 100,
    });

    useEffect(() => {
        setDataGridState(prevState => ({
            ...prevState,
            columns: getPersonTableFields(loading, onExpire, onDetail)
        }));
    }, [loading])

    useEffect(() => {
        if (loading) return;
        
        setDataGridState(prevState => ({
            ...prevState,
            rows: data?.persons ?? []
        }));
    }, [loading, data])

    return (
        <>
            <ManageTable
                loading={loading}
                headerTitle='Manage Users'
                // title="Users"
                onAddNew={onAddNew}
                onBack={onBack}
                // pagination
                // paginationMode={"client"}
                // page={search.hasQuery ? 0 : dataGridState.page}
                // pageSize={10}
                rows={dataGridState.rows}
                // rowCount={search.hasQuery ? dataGridState.rows?.length ?? 0 : dataGridState.rowCount}
                columns={dataGridState.columns}
                // rowsPerPageOptions={[10]}
                disableBoxShadow={false}
                // disableColumnSelector
                // disableColumnMenu
            />
        </>
    )
}

export default PersonTable;