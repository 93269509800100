import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import errorLink from "./errorLink";
import {relayStylePagination} from "@apollo/client/utilities";



import usePersonAuthStore from "../../pages/Admin/personAuthStore";
import usePersonStore from "../../pages/Admin/personStore";

const loggerLink = new ApolloLink((operation, forward) => {
  console.log(`GraphQL Request:`, operation);
  operation.setContext({ start: new Date() });
  
  return forward(operation).map((response) => {
    const responseTime = Date.now() - operation.getContext().start;
    console.log(`GraphQL Response took: ${responseTime}`);
    return response;
  });
});

function Apollo({ children }: any) {

  const personAccessToken = usePersonAuthStore(state => state.access_token);
  const companyId = usePersonStore(state => state?.company?.id ?? null);
  const locationId = usePersonStore(state => state?.company?.defaultLocation?.id ?? null);

  const httpLink = createHttpLink({
    uri: `${window.__env?.API_URL }/graphql/`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    var authToken = personAccessToken;

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'CompanyId': companyId,
        'LocationId': locationId,
        authorization: authToken ? `Bearer ${authToken}` : "",
      }
    }
  });

  const links = ApolloLink.from([
    loggerLink,
    authLink,
    errorLink,
    httpLink
  ]);

  const apolloClient = new ApolloClient({
    link: links,
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            students: relayStylePagination()
          },
        }
      }
    })
  });

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  )
}

export default Apollo;
